html {
  background-color: #fff;
}
.App {
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  padding-top: 50px;
  padding: 20px;
}
a {
  color: #000;
  font-size: 1rem;
  text-decoration: none;
}
.banner {
  margin-left: 9px;
  margin-bottom: 0.5rem;
  margin-top: 2rem;
}
h1 {
  font-size: 1.5rem;
  display: block;
  font-weight: bold;
  padding-bottom: 0px;
  margin-bottom: 1px;
  width: 100%;
  color: #000;
}
img {
  width: 33%;
  position: relative;
  left: 0;
  margin-top: 0.3rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
ul {
  text-transform: uppercase;
  width: 100%;
  text-align: left;
  margin: 0;
  padding: 0;
  margin-bottom: 1.5rem;
}
ul li {
  font-weight: bold;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 0px;
  border-bottom: 1px solid blue;
  a {
    font-size: 2rem;
  }
}
ul li:first-child {
  border-top: 1px solid blue;
}

ul li a {
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 10px;
  width: 100%;
  display: block;
  text-decoration: none;
  color: #333;
}
ul li:hover {
  background-color: #efefef;
}
ul li:hover a {
  color: #2945a0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.contact {
  color: #666;
}
.App-header {
  background-color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 1.5rem;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
